import { FC, useCallback, useEffect, useState } from 'react';
import { HomePageSidebar } from './HomePageSidebar/HomePageSidebar';
import cls from './header.module.scss';
import { useLogoutApi } from '../api/logoutRTK';
import { authDataActions } from '@/entities/authData';
import LogOutSVG from '@/shared/assets/icons/Logout.svg';
import { getRouteSignIn } from '@/shared/consts/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppNavigate } from '@/shared/lib/hooks';
import { Burger } from '@/shared/ui/Burger/Burger';
import { LoaderUi } from '@/shared/ui/Loader/LoaderUi';
import { PTag } from '@/shared/ui/Paragraph/P';
import { HStack } from '@/shared/ui/Stack';

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const [logout, { isLoading, isSuccess }] = useLogoutApi();
  const [open, setOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const onLogOut = useCallback(async () => {
    await logout();
  }, [logout]);

  const onCloseModal = useCallback(() => {
    setOpen(false);
  }, []);
  const onOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const logOut = useCallback(async () => {
    await onLogOut();
  }, [onLogOut]);

  const handleSize = useCallback(
    () => setIsScrolling(Boolean(window.scrollY > 10)),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleSize);
    return () => window.removeEventListener('scroll', handleSize);
  }, [handleSize]);

  useEffect(() => {
    if (isSuccess) {
      localStorage.clear();
      dispatch(authDataActions.setLogout());
      navigate(getRouteSignIn());
    }
  }, [dispatch, isSuccess, navigate]);

  return (
    <div
      className={classNames(cls.header, { [cls.isScroll]: isScrolling }, [])}
    >
      <Burger
        open={open}
        className={classNames(cls.Burger)}
        handlerModal={onOpenModal}
      />
      <HStack gap={0.5} justify='start' className={cls.logout} onClick={logOut}>
        <LogOutSVG />
        <PTag tage='P2'> Выйти</PTag>
        {!!isLoading && <LoaderUi className={cls.loader} />}
      </HStack>
      <HomePageSidebar isOpen={open} onClose={onCloseModal} />
    </div>
  );
};
