import { memo, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRouter } from './providers/router';
import { getAuth } from '@/entities/authData';
import { classNames } from '@/shared/lib/classNames/classNames';
import './styles/index.scss';
import { useAppSelector } from '@/shared/lib/hooks';
import { Header } from '@/widgets/Header';

export const App = memo(() => {
  const { pathname } = useLocation();
  const isAuth = useAppSelector(getAuth);
  return (
    <div className={classNames('app', {}, ['app_light_theme'])}>
      {!!isAuth && pathname !== '/' && <Header />}
      <Suspense fallback=''>
        <AppRouter />
      </Suspense>
    </div>
  );
});
