import React, { memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import cls from './HomePageSidebar.module.scss';
import Blog from '@/shared/assets/icons/article-1-svgrepo-com.svg';
import Banner from '@/shared/assets/icons/banner.svg';
import Category from '@/shared/assets/icons/category.svg';
import Discount from '@/shared/assets/icons/discount.svg';
import Order from '@/shared/assets/icons/order.svg';
import Site from '@/shared/assets/icons/site-svgrepo-com.svg';
import Storage from '@/shared/assets/icons/storage-svgrepo-com.svg';
import Store from '@/shared/assets/icons/store-sign-svgrepo-com.svg';
import Telegram from '@/shared/assets/icons/telegram-svgrepo-com (1).svg';
import {
  getRouteMyStorageInSite,
  getRouteMyStorage,
  getRouteTelegramAdmins,
  getRouteStores,
  getRouteBlogs,
  getRouteOrders,
  getRouteAddBanners,
  getRouteCategory,
  getRouteDiscounts,
} from '@/shared/consts/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppNavigate, useModal } from '@/shared/lib/hooks';
import { Burger } from '@/shared/ui/Burger/Burger';
import { Overlay } from '@/shared/ui/Overlay';
import { PTag } from '@/shared/ui/Paragraph/P';
import { Portal } from '@/shared/ui/Portal/Portal';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface HomePageSidebarProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}
export const HomePageSidebarUI = (props: HomePageSidebarProps) => {
  const { className, isOpen, onClose } = props;
  const { pathname } = useLocation();
  const navigate = useAppNavigate();

  const onCloseSide = useCallback(() => {
    onClose();
  }, [onClose]);

  const { close, isClosing, isMounted } = useModal({
    animationDelay: 250,
    isOpen,
    onClose: onCloseSide,
  });

  const sidebarList = useMemo(
    () => [
      { icon: Storage, link: getRouteMyStorageInSite(), title: 'Мой склад' },
      { icon: Site, link: getRouteMyStorage(), title: 'Мой сайт' },
      { icon: Category, link: getRouteCategory(), title: 'Категории' },
      { icon: Order, link: getRouteOrders(), title: 'Заказы' },
      { icon: Store, link: getRouteStores(), title: 'Магазины' },
      {
        icon: Telegram,
        link: getRouteTelegramAdmins(),
        title: 'Телеграмм менеджеры',
      },
      { icon: Blog, link: getRouteBlogs(), title: 'Блоги' },
      { icon: Discount, link: getRouteDiscounts(), title: 'Новости/акции' },
      { icon: Banner, link: getRouteAddBanners(), title: 'Баннеры' },
    ],
    [],
  );

  const handleLink = useCallback(
    (link: string) => {
      navigate(link);
      close();
    },
    [navigate, close],
  );

  const mode = {
    [cls.opened]: isOpen,
    [cls.closed]: isClosing,
  };

  if (!isMounted) return null;
  return (
    <Portal>
      <div className={classNames(cls.HomePageSidebar, mode, [className])}>
        <Overlay onClick={close} />
        <VStack
          max
          gap={1}
          style={{ paddingTop: '1rem' }}
          className={classNames(cls.content, {}, [])}
        >
          <HStack max justify='end'>
            <Burger
              open={isOpen}
              className={classNames(cls.Burger)}
              handlerModal={close}
            />
          </HStack>
          <VStack max gap={0.5} className={cls.itemsWrapper}>
            {sidebarList.map(item => (
              <HStack
                key={item.link}
                max
                gap={0.5}
                className={classNames(cls.items, {
                  [cls.active]: pathname === item.link,
                })}
                onClick={() => handleLink(item.link)}
              >
                <item.icon className={cls.icon} />
                <PTag tage='P2'>{item.title}</PTag>
              </HStack>
            ))}
          </VStack>
        </VStack>
      </div>
    </Portal>
  );
};

export const HomePageSidebar = memo(HomePageSidebarUI);
