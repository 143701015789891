import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthData, AuthDataSchema } from '../types/authData';

const initialState: AuthDataSchema = {
  isAuth: false,
  sessionTime: undefined,
};

export const authDataSlice = createSlice({
  initialState,
  name: 'authData',
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<string | undefined>) => {
      state.accessToken = payload;
    },
    setAuthData: (state, { payload }: PayloadAction<AuthData | undefined>) => {
      state.authData = payload;
      state.accessToken = payload?.accessToken;
    },
    setIsAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuth = payload;
    },
    setLogout: state => {
      state.authData = undefined;
      state.accessToken = undefined;
      state.isAuth = false;
    },
  },
});

export const { actions: authDataActions } = authDataSlice;
export const { reducer: authDataReducer } = authDataSlice;
