import cls from './PageError.module.scss';
import { classNames } from '@/shared/lib/classNames/classNames';
import { ButtonUi } from '@/shared/ui/Buttons/ButtonUi/ButtonUi';

export interface PageErrorProps {
  className?: string;
}
export const PageError = ({ className }: PageErrorProps) => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={classNames(cls.PageError, {}, [className])}>
      <p className={classNames(cls.info)}>произошла непредвиденная ошибка</p>
      <ButtonUi
        size='L'
        name='reload'
        layOut='TextOnly'
        theme='primary'
        className={cls.btn}
        onClick={reloadPage}
      >
        Обновить страницу
      </ButtonUi>
    </div>
  );
};
