import { rtkApi } from '@/shared/api/rtkApi';

interface Body {
  method: string;
}
const logoutApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    logout: build.mutation<Iresponse<string>, Partial<void>>({
      query: payload => ({
        method: 'GET',
        url: '/auth/logout',
      }),
    }),
  }),
});

export const useLogoutApi = logoutApi.useLogoutMutation;
