import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBlogs } from '../service/fetchBlogs';
import { BlogsSchema } from '../types/blog';

const initialState: BlogsSchema = {
  error: undefined,
  isLoading: true,
  step: 10,
};

const BlogsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchBlogs.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(fetchBlogs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.articles = payload.data;
        state.totalPages = payload.count;
      })
      .addCase(fetchBlogs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'articles',
  reducers: {
    setClear: state => {
      state.isLoading = false;
      state.error = undefined;
      state.selectedBlog = undefined;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    setStep: (state, { payload }: PayloadAction<string>) => {
      state.step = Number(payload);
    },
  },
});

export const { actions: blogsActions } = BlogsSlice;
export const { reducer: blogsReducer } = BlogsSlice;
