import { AddBlogPage } from '@/pages/AddBlogPage';
import { AuthPage } from '@/pages/AuthPage';
import { BannersPage } from '@/pages/BannersPage';
import { BlogPage } from '@/pages/BlogsPage';
import { CategoryPage } from '@/pages/CategoryPage';
import { DiscountsPage } from '@/pages/DiscountsPage';
import { EditBlogPage } from '@/pages/EditBlogPage';
import { TelegramManagersPage } from '@/pages/ManagersPage';
import { MySitePage } from '@/pages/MySitePage';
import { NotfoundPage } from '@/pages/NotFoundPage';
import { OrdersPage } from '@/pages/OrdersPage';
import { MyStoragePage } from '@/pages/SkladPage';
import { StoresPage } from '@/pages/Stores';
import {
  AppRoutes,
  getRouteAddBanners,
  getRouteAddBlogs,
  getRouteBlogs,
  getRouteCategory,
  getRouteDiscounts,
  getRouteEditBlogs,
  getRouteMyStorage,
  getRouteMyStorageInSite,
  getRouteOrders,
  getRouteSignIn,
  getRouteStores,
  getRouteTelegramAdmins,
} from '@/shared/consts/router';
import { AppRoutesProps } from '@/shared/types/router';

export const routesConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.SIGN_IN]: {
    authOnly: false,
    element: <AuthPage />,
    path: getRouteSignIn(),
  },
  [AppRoutes.BLOGS]: {
    authOnly: true,
    element: <BlogPage />,
    path: getRouteBlogs(),
  },
  [AppRoutes.ADD_BLOGS]: {
    authOnly: true,
    element: <AddBlogPage />,
    path: getRouteAddBlogs(),
  },
  [AppRoutes.EDIT_BLOGS]: {
    authOnly: true,
    element: <EditBlogPage />,
    path: getRouteEditBlogs(':id'),
  },
  [AppRoutes.STORES]: {
    authOnly: true,
    element: <StoresPage />,
    path: getRouteStores(),
  },

  [AppRoutes.TELEGRAM_ADMINS]: {
    authOnly: true,
    element: <TelegramManagersPage />,
    path: getRouteTelegramAdmins(),
  },

  [AppRoutes.MY_SITE]: {
    authOnly: true,
    element: <MySitePage />,
    path: getRouteMyStorage(),
  },

  [AppRoutes.MY_STORAGE_IN_SITE]: {
    authOnly: true,
    element: <MyStoragePage />,
    path: getRouteMyStorageInSite(),
  },

  [AppRoutes.ORDERS]: {
    authOnly: true,
    element: <OrdersPage />,
    path: getRouteOrders(),
  },
  [AppRoutes.ADD_BANNERS]: {
    authOnly: true,
    element: <BannersPage />,
    path: getRouteAddBanners(),
  },
  [AppRoutes.CATEGORY]: {
    authOnly: true,
    element: <CategoryPage />,
    path: getRouteCategory(),
  },
  [AppRoutes.DISCOUNTS]: {
    authOnly: true,
    element: <DiscountsPage />,
    path: getRouteDiscounts(),
  },

  // last
  [AppRoutes.NOTFOUND]: {
    element: <NotfoundPage />,
    path: '*',
  },
};
