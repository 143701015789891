export enum AppRoutes {
  SIGN_IN = 'SignIn',
  MY_SITE = 'my_site',
  MY_STORAGE_IN_SITE = 'my_storage_in_site',
  TELEGRAM_ADMINS = 'telegram_admins',
  STORES = 'stores',
  BLOGS = 'blogs',
  ADD_BLOGS = 'add_blog',
  EDIT_BLOGS = 'edit_blog',
  ORDERS = 'orders',
  ADD_BANNERS = 'banners',
  DISCOUNTS = 'discounts',
  CATEGORY = 'category',
  NOTFOUND = 'not_found',
}

export const getRouteSignIn = () => '/';
export const getRouteMyStorage = () => '/my_site';
export const getRouteMyStorageInSite = () => '/my_storage_in_site';
export const getRouteTelegramAdmins = () => '/telegram_admins';
export const getRouteStores = () => '/stores';
export const getRouteBlogs = () => '/blogs';
export const getRouteAddBlogs = () => '/blogs/create';
export const getRouteEditBlogs = (id: string) => `/blogs/edit/${id}`;
export const getRouteOrders = () => '/orders';
export const getRouteAddBanners = () => '/banners';
export const getRouteDiscounts = () => '/discounts';
export const getRouteCategory = () => '/category';
