/* eslint-disable react/button-has-type */
import { memo } from 'react';
import cls from './Burger.module.scss';
import { classNames, Mods } from '../../lib/classNames/classNames';

export interface BurgerProps {
  className?: string;
  open: boolean;
  handlerModal: () => void;
}

const BurgerUI = (props: BurgerProps) => {
  const { className, open, handlerModal, ...otherProps } = props;

  const mods: Mods = {
    [cls.opened]: open,
  };

  return (
    <button
      className={classNames(cls.Burger, mods, [className])}
      onClick={handlerModal}
      {...otherProps}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export const Burger = memo(BurgerUI);
