import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCategoriesItem } from '../service/fetchCategory';
import { CategoriesItemSchema } from '../types/categoryType';

const initialState: CategoriesItemSchema = {
  isLoading: true,
};

export const categoriesItemSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchCategoriesItem.pending, (state, { payload, meta }) => {
        state.errors = undefined;
        state.isLoading = true;
      })
      .addCase(
        fetchCategoriesItem.fulfilled,
        (state, { payload }: PayloadAction<Iresponse<Category[]>>) => {
          state.isLoading = false;
          state.errors = undefined;
          state.result = payload.data;
        },
      )
      .addCase(fetchCategoriesItem.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload;
      });
  },
  initialState,
  name: 'categoriesItem',
  reducers: {},
});

export const { actions: categoriesItemActions } = categoriesItemSlice;
export const { reducer: categoriesItemReducer } = categoriesItemSlice;
