import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';

export const getCategoriesItem = (state: StateSchema) =>
  state.categoriesItem.result;
export const getIsLoadingCategory = (state: StateSchema) =>
  state.categoriesItem?.isLoading;

export const getCatagories = createSelector(getCategoriesItem, allItems => {
  const category = allItems?.filter(i => i.parentCategory === null);
  return category;
});
