import { Navigate, useLocation } from 'react-router-dom';
import { getAuth } from '@/entities/authData';
import { getRouteSignIn } from '@/shared/consts/router';
import { useAppSelector } from '@/shared/lib/hooks';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuth = useAppSelector(getAuth);
  const location = useLocation();

  if (!isAuth)
    return (
      <Navigate replace to={getRouteSignIn()} state={{ from: location }} />
    );

  return children;
}
