import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StorProvider';

interface FetchCategoryProps {
  replace?: boolean;
  search?: string;
  page: number;
  size: number;
}

export const fetchCategoriesItem = createAsyncThunk<
  Iresponse<Category[]>,
  FetchCategoryProps,
  ThunkConfig<string[]>
>('Category/fetchCategoryItems', async (props, { extra, rejectWithValue }) => {
  const params = {
    filters: {},
    page: props.page,
    search: props.search,
    size: props.size,
  };
  try {
    const response = await extra.api.post<Iresponse<Category[]>>(
      'categories',
      params,
    );
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
