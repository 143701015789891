import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPage, getSearch, getStep } from '../selector/getBlogItems';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { Blog } from '@/entities/Blog';

interface BlogParams {
  replace: boolean;
}

export const fetchBlogs = createAsyncThunk<
  Iresponse<Blog[]>,
  BlogParams,
  ThunkConfig<string[]>
>('blogs/fetchBlogsData', async (_, { extra, rejectWithValue, getState }) => {
  try {
    const page = getPage(getState());
    const size = getStep(getState());
    const search = getSearch(getState());
    const response = await extra.api.post<Iresponse<Blog[]>>('blogs', {
      filters: {},
      page,
      search,
      size,
    });
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
